export const Countries = [
	{
		Name: "Afghanistan",
		Code: "AF",
		Timezone: "Afghanistan Standard Time",
		UTC: "UTC+04:30",
		MobileCode: "+93",
	},
	{
		Name: "Åland Islands",
		Code: "AX",
		Timezone: "FLE Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+358-18",
	},
	{
		Name: "Albania",
		Code: "AL",
		Timezone: "Central Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+355",
	},
	{
		Name: "Algeria",
		Code: "DZ",
		Timezone: "W. Central Africa Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+213",
	},
	{
		Name: "American Samoa",
		Code: "AS",
		Timezone: "UTC-11",
		UTC: "UTC-11:00",
		MobileCode: "+1-684",
	},
	{
		Name: "Andorra",
		Code: "AD",
		Timezone: "W. Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+376",
	},
	{
		Name: "Angola",
		Code: "AO",
		Timezone: "W. Central Africa Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+244",
	},
	{
		Name: "Anguilla",
		Code: "AI",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+1-264",
	},
	{
		Name: "Antarctica",
		Code: "AQ",
		Timezone: "Pacific SA Standard Time",
		UTC: "UTC-03:00",
		MobileCode: "+",
	},
	{
		Name: "Antigua and Barbuda",
		Code: "AG",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+1-268",
	},
	{
		Name: "Argentina",
		Code: "AR",
		Timezone: "Argentina Standard Time",
		UTC: "UTC-03:00",
		MobileCode: "+54",
	},
	{
		Name: "Armenia",
		Code: "AM",
		Timezone: "Caucasus Standard Time",
		UTC: "UTC+04:00",
		MobileCode: "+374",
	},
	{
		Name: "Aruba",
		Code: "AW",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+297",
	},
	{
		Name: "Australia",
		Code: "AU",
		Timezone: "AUS Eastern Standard Time",
		UTC: "UTC+10:00",
		MobileCode: "+61",
	},
	{
		Name: "Austria",
		Code: "AT",
		Timezone: "W. Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+43",
	},
	{
		Name: "Azerbaijan",
		Code: "AZ",
		Timezone: "Azerbaijan Standard Time",
		UTC: "UTC+04:00",
		MobileCode: "+994",
	},
	{
		Name: "Bahamas, The",
		Code: "BS",
		Timezone: "Eastern Standard Time",
		UTC: "UTC-05:00",
		MobileCode: "+1-242",
	},
	{
		Name: "Bahrain",
		Code: "BH",
		Timezone: "Arab Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+973",
	},
	{
		Name: "Bangladesh",
		Code: "BD",
		Timezone: "Bangladesh Standard Time",
		UTC: "UTC+06:00",
		MobileCode: "+880",
	},
	{
		Name: "Barbados",
		Code: "BB",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+1-246",
	},
	{
		Name: "Belarus",
		Code: "BY",
		Timezone: "Belarus Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+375",
	},
	{
		Name: "Belgium",
		Code: "BE",
		Timezone: "Romance Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+32",
	},
	{
		Name: "Belize",
		Code: "BZ",
		Timezone: "Central America Standard Time",
		UTC: "UTC-06:00",
		MobileCode: "+501",
	},
	{
		Name: "Benin",
		Code: "BJ",
		Timezone: "W. Central Africa Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+229",
	},
	{
		Name: "Bermuda",
		Code: "BM",
		Timezone: "Atlantic Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+1-441",
	},
	{
		Name: "Bhutan",
		Code: "BT",
		Timezone: "Bangladesh Standard Time",
		UTC: "UTC+06:00",
		MobileCode: "+975",
	},
	{
		Name: "Bolivarian Republic of Venezuela",
		Code: "VE",
		Timezone: "Venezuela Standard Time",
		UTC: "UTC-04:30",
		MobileCode: "+58",
	},
	{
		Name: "Bolivia",
		Code: "BO",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+591",
	},
	{
		Name: "Bonaire, Sint Eustatius and Saba",
		Code: "BQ",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+599",
	},
	{
		Name: "Bosnia and Herzegovina",
		Code: "BA",
		Timezone: "Central European Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+387",
	},
	{
		Name: "Botswana",
		Code: "BW",
		Timezone: "South Africa Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+267",
	},
	{
		Name: "Bouvet Island",
		Code: "BV",
		Timezone: "UTC",
		UTC: "UTC",
		MobileCode: "+",
	},
	{
		Name: "Brazil",
		Code: "BR",
		Timezone: "E. South America Standard Time",
		UTC: "UTC-03:00",
		MobileCode: "+55",
	},
	{
		Name: "British Indian Ocean Territory",
		Code: "IO",
		Timezone: "Central Asia Standard Time",
		UTC: "UTC+06:00",
		MobileCode: "+246",
	},
	{
		Name: "Brunei",
		Code: "BN",
		Timezone: "Singapore Standard Time",
		UTC: "UTC+08:00",
		MobileCode: "+673",
	},
	{
		Name: "Bulgaria",
		Code: "BG",
		Timezone: "FLE Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+359",
	},
	{
		Name: "Burkina Faso",
		Code: "BF",
		Timezone: "Greenwich Standard Time",
		UTC: "UTC",
		MobileCode: "+226",
	},
	{
		Name: "Burundi",
		Code: "BI",
		Timezone: "South Africa Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+257",
	},
	{
		Name: "Cabo Verde",
		Code: "CV",
		Timezone: "Cape Verde Standard Time",
		UTC: "UTC-01:00",
		MobileCode: "+238",
	},
	{
		Name: "Cambodia",
		Code: "KH",
		Timezone: "SE Asia Standard Time",
		UTC: "UTC+07:00",
		MobileCode: "+855",
	},
	{
		Name: "Cameroon",
		Code: "CM",
		Timezone: "W. Central Africa Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+237",
	},
	{
		Name: "Canada",
		Code: "CA",
		Timezone: "Eastern Standard Time",
		UTC: "UTC-05:00",
		MobileCode: "+1",
	},
	{
		Name: "Cayman Islands",
		Code: "KY",
		Timezone: "SA Pacific Standard Time",
		UTC: "UTC-05:00",
		MobileCode: "+1-345",
	},
	{
		Name: "Central African Republic",
		Code: "CF",
		Timezone: "W. Central Africa Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+236",
	},
	{
		Name: "Chad",
		Code: "TD",
		Timezone: "W. Central Africa Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+235",
	},
	{
		Name: "Chile",
		Code: "CL",
		Timezone: "Pacific SA Standard Time",
		UTC: "UTC-03:00",
		MobileCode: "+56",
	},
	{
		Name: "China",
		Code: "CN",
		Timezone: "China Standard Time",
		UTC: "UTC+08:00",
		MobileCode: "+86",
	},
	{
		Name: "Christmas Island",
		Code: "CX",
		Timezone: "SE Asia Standard Time",
		UTC: "UTC+07:00",
		MobileCode: "+61",
	},
	{
		Name: "Cocos (Keeling) Islands",
		Code: "CC",
		Timezone: "Myanmar Standard Time",
		UTC: "UTC+06:30",
		MobileCode: "+61",
	},
	{
		Name: "Colombia",
		Code: "CO",
		Timezone: "SA Pacific Standard Time",
		UTC: "UTC-05:00",
		MobileCode: "+57",
	},
	{
		Name: "Comoros",
		Code: "KM",
		Timezone: "E. Africa Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+269",
	},
	{
		Name: "Congo",
		Code: "CG",
		Timezone: "W. Central Africa Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+242",
	},
	{
		Name: "Congo (DRC)",
		Code: "CD",
		Timezone: "W. Central Africa Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+243",
	},
	{
		Name: "Cook Islands",
		Code: "CK",
		Timezone: "Hawaiian Standard Time",
		UTC: "UTC-10:00",
		MobileCode: "+682",
	},
	{
		Name: "Costa Rica",
		Code: "CR",
		Timezone: "Central America Standard Time",
		UTC: "UTC-06:00",
		MobileCode: "+506",
	},
	{
		Name: "Côte d'Ivoire",
		Code: "CI",
		Timezone: "Greenwich Standard Time",
		UTC: "UTC",
		MobileCode: "+225",
	},
	{
		Name: "Croatia",
		Code: "HR",
		Timezone: "Central European Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+385",
	},
	{
		Name: "Cuba",
		Code: "CU",
		Timezone: "Eastern Standard Time",
		UTC: "UTC-05:00",
		MobileCode: "+53",
	},
	{
		Name: "Curaçao",
		Code: "CW",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+599",
	},
	{
		Name: "Cyprus",
		Code: "CY",
		Timezone: "E. Europe Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+357",
	},
	{
		Name: "Czech Republic",
		Code: "CZ",
		Timezone: "Central Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+420",
	},
	{
		Name: "Democratic Republic of Timor-Leste",
		Code: "TL",
		Timezone: "Tokyo Standard Time",
		UTC: "UTC+09:00",
		MobileCode: "+670",
	},
	{
		Name: "Denmark",
		Code: "DK",
		Timezone: "Romance Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+45",
	},
	{
		Name: "Djibouti",
		Code: "DJ",
		Timezone: "E. Africa Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+253",
	},
	{
		Name: "Dominica",
		Code: "DM",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+1-767",
	},
	{
		Name: "Dominican Republic",
		Code: "DO",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+1-809 and 1-829",
	},
	{
		Name: "Ecuador",
		Code: "EC",
		Timezone: "SA Pacific Standard Time",
		UTC: "UTC-05:00",
		MobileCode: "+593",
	},
	{
		Name: "Egypt",
		Code: "EG",
		Timezone: "Egypt Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+20",
	},
	{
		Name: "El Salvador",
		Code: "SV",
		Timezone: "Central America Standard Time",
		UTC: "UTC-06:00",
		MobileCode: "+503",
	},
	{
		Name: "Equatorial Guinea",
		Code: "GQ",
		Timezone: "W. Central Africa Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+240",
	},
	{
		Name: "Eritrea",
		Code: "ER",
		Timezone: "E. Africa Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+291",
	},
	{
		Name: "Estonia",
		Code: "EE",
		Timezone: "FLE Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+372",
	},
	{
		Name: "Ethiopia",
		Code: "ET",
		Timezone: "E. Africa Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+251",
	},
	{
		Name: "Falkland Islands (Islas Malvinas)",
		Code: "FK",
		Timezone: "SA Eastern Standard Time",
		UTC: "UTC-03:00",
		MobileCode: "+500",
	},
	{
		Name: "Faroe Islands",
		Code: "FO",
		Timezone: "GMT Standard Time",
		UTC: "UTC",
		MobileCode: "+298",
	},
	{
		Name: "Fiji Islands",
		Code: "FJ",
		Timezone: "Fiji Standard Time",
		UTC: "UTC+12:00",
		MobileCode: "+679",
	},
	{
		Name: "Finland",
		Code: "FI",
		Timezone: "FLE Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+358",
	},
	{
		Name: "France",
		Code: "FR",
		Timezone: "Romance Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+33",
	},
	{
		Name: "French Guiana",
		Code: "GF",
		Timezone: "SA Eastern Standard Time",
		UTC: "UTC-03:00",
		MobileCode: "+594",
	},
	{
		Name: "French Polynesia",
		Code: "PF",
		Timezone: "Hawaiian Standard Time",
		UTC: "UTC-10:00",
		MobileCode: "+689",
	},
	{
		Name: "French Southern and Antarctic Lands",
		Code: "TF",
		Timezone: "West Asia Standard Time",
		UTC: "UTC+05:00",
		MobileCode: "+",
	},
	{
		Name: "Gabon",
		Code: "GA",
		Timezone: "W. Central Africa Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+241",
	},
	{
		Name: "Gambia, The",
		Code: "GM",
		Timezone: "Greenwich Standard Time",
		UTC: "UTC",
		MobileCode: "+220",
	},
	{
		Name: "Georgia",
		Code: "GE",
		Timezone: "Georgian Standard Time",
		UTC: "UTC+04:00",
		MobileCode: "+995",
	},
	{
		Name: "Germany",
		Code: "DE",
		Timezone: "W. Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+49",
	},
	{
		Name: "Ghana",
		Code: "GH",
		Timezone: "Greenwich Standard Time",
		UTC: "UTC",
		MobileCode: "+233",
	},
	{
		Name: "Gibraltar",
		Code: "GI",
		Timezone: "W. Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+350",
	},
	{
		Name: "Greece",
		Code: "GR",
		Timezone: "GTB Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+30",
	},
	{
		Name: "Greenland",
		Code: "GL",
		Timezone: "Greenland Standard Time",
		UTC: "UTC-03:00",
		MobileCode: "+299",
	},
	{
		Name: "Grenada",
		Code: "GD",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+1-473",
	},
	{
		Name: "Guadeloupe",
		Code: "GP",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+590",
	},
	{
		Name: "Guam",
		Code: "GU",
		Timezone: "West Pacific Standard Time",
		UTC: "UTC+10:00",
		MobileCode: "+1-671",
	},
	{
		Name: "Guatemala",
		Code: "GT",
		Timezone: "Central America Standard Time",
		UTC: "UTC-06:00",
		MobileCode: "+502",
	},
	{
		Name: "Guernsey",
		Code: "GG",
		Timezone: "GMT Standard Time",
		UTC: "UTC",
		MobileCode: "+44-1481",
	},
	{
		Name: "Guinea",
		Code: "GN",
		Timezone: "Greenwich Standard Time",
		UTC: "UTC",
		MobileCode: "+224",
	},
	{
		Name: "Guinea-Bissau",
		Code: "GW",
		Timezone: "Greenwich Standard Time",
		UTC: "UTC",
		MobileCode: "+245",
	},
	{
		Name: "Guyana",
		Code: "GY",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+592",
	},
	{
		Name: "Haiti",
		Code: "HT",
		Timezone: "Eastern Standard Time",
		UTC: "UTC-05:00",
		MobileCode: "+509",
	},
	{
		Name: "Heard Island and McDonald Islands",
		Code: "HM",
		Timezone: "Mauritius Standard Time",
		UTC: "UTC+04:00",
		MobileCode: "+ ",
	},
	{
		Name: "Honduras",
		Code: "HN",
		Timezone: "Central America Standard Time",
		UTC: "UTC-06:00",
		MobileCode: "+504",
	},
	{
		Name: "Hong Kong SAR",
		Code: "HK",
		Timezone: "China Standard Time",
		UTC: "UTC+08:00",
		MobileCode: "+852",
	},
	{
		Name: "Hungary",
		Code: "HU",
		Timezone: "Central Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+36",
	},
	{
		Name: "Iceland",
		Code: "IS",
		Timezone: "Greenwich Standard Time",
		UTC: "UTC",
		MobileCode: "+354",
	},
	{
		Name: "India",
		Code: "IN",
		Timezone: "India Standard Time",
		UTC: "UTC+05:30",
		MobileCode: "+91",
	},
	{
		Name: "Indonesia",
		Code: "ID",
		Timezone: "SE Asia Standard Time",
		UTC: "UTC+07:00",
		MobileCode: "+62",
	},
	{
		Name: "Iran",
		Code: "IR",
		Timezone: "Iran Standard Time",
		UTC: "UTC+03:30",
		MobileCode: "+98",
	},
	{
		Name: "Iraq",
		Code: "IQ",
		Timezone: "Arabic Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+964",
	},
	{
		Name: "Ireland",
		Code: "IE",
		Timezone: "GMT Standard Time",
		UTC: "UTC",
		MobileCode: "+353",
	},
	{
		Name: "Israel",
		Code: "IL",
		Timezone: "Israel Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+972",
	},
	{
		Name: "Italy",
		Code: "IT",
		Timezone: "W. Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+39",
	},
	{
		Name: "Jamaica",
		Code: "JM",
		Timezone: "SA Pacific Standard Time",
		UTC: "UTC-05:00",
		MobileCode: "+1-876",
	},
	{
		Name: "Jan Mayen",
		Code: "SJ",
		Timezone: "W. Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+47",
	},
	{
		Name: "Japan",
		Code: "JP",
		Timezone: "Tokyo Standard Time",
		UTC: "UTC+09:00",
		MobileCode: "+81",
	},
	{
		Name: "Jersey",
		Code: "JE",
		Timezone: "GMT Standard Time",
		UTC: "UTC",
		MobileCode: "+44-1534",
	},
	{
		Name: "Jordan",
		Code: "JO",
		Timezone: "Jordan Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+962",
	},
	{
		Name: "Kazakhstan",
		Code: "KZ",
		Timezone: "Central Asia Standard Time",
		UTC: "UTC+06:00",
		MobileCode: "+7",
	},
	{
		Name: "Kenya",
		Code: "KE",
		Timezone: "E. Africa Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+254",
	},
	{
		Name: "Kiribati",
		Code: "KI",
		Timezone: "UTC+12",
		UTC: "UTC+12:00",
		MobileCode: "+686",
	},
	{
		Name: "Korea",
		Code: "KR",
		Timezone: "Korea Standard Time",
		UTC: "UTC+09:00",
		MobileCode: "+82",
	},
	{
		Name: "Kosovo",
		Code: "XK",
		Timezone: "Central European Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+",
	},
	{
		Name: "Kuwait",
		Code: "KW",
		Timezone: "Arab Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+965",
	},
	{
		Name: "Kyrgyzstan",
		Code: "KG",
		Timezone: "Central Asia Standard Time",
		UTC: "UTC+06:00",
		MobileCode: "+996",
	},
	{
		Name: "Laos",
		Code: "LA",
		Timezone: "SE Asia Standard Time",
		UTC: "UTC+07:00",
		MobileCode: "+856",
	},
	{
		Name: "Latvia",
		Code: "LV",
		Timezone: "FLE Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+371",
	},
	{
		Name: "Lebanon",
		Code: "LB",
		Timezone: "Middle East Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+961",
	},
	{
		Name: "Lesotho",
		Code: "LS",
		Timezone: "South Africa Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+266",
	},
	{
		Name: "Liberia",
		Code: "LR",
		Timezone: "Greenwich Standard Time",
		UTC: "UTC",
		MobileCode: "+231",
	},
	{
		Name: "Libya",
		Code: "LY",
		Timezone: "E. Europe Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+218",
	},
	{
		Name: "Liechtenstein",
		Code: "LI",
		Timezone: "W. Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+423",
	},
	{
		Name: "Lithuania",
		Code: "LT",
		Timezone: "FLE Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+370",
	},
	{
		Name: "Luxembourg",
		Code: "LU",
		Timezone: "W. Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+352",
	},
	{
		Name: "Macao SAR",
		Code: "MO",
		Timezone: "China Standard Time",
		UTC: "UTC+08:00",
		MobileCode: "+853",
	},
	{
		Name: "Macedonia, Former Yugoslav Republic of",
		Code: "MK",
		Timezone: "Central European Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+389",
	},
	{
		Name: "Madagascar",
		Code: "MG",
		Timezone: "E. Africa Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+261",
	},
	{
		Name: "Malawi",
		Code: "MW",
		Timezone: "South Africa Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+265",
	},
	{
		Name: "Malaysia",
		Code: "MY",
		Timezone: "Singapore Standard Time",
		UTC: "UTC+08:00",
		MobileCode: "+60",
	},
	{
		Name: "Maldives",
		Code: "MV",
		Timezone: "West Asia Standard Time",
		UTC: "UTC+05:00",
		MobileCode: "+960",
	},
	{
		Name: "Mali",
		Code: "ML",
		Timezone: "Greenwich Standard Time",
		UTC: "UTC",
		MobileCode: "+223",
	},
	{
		Name: "Malta",
		Code: "MT",
		Timezone: "W. Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+356",
	},
	{
		Name: "Man, Isle of",
		Code: "IM",
		Timezone: "GMT Standard Time",
		UTC: "UTC",
		MobileCode: "+44-1624",
	},
	{
		Name: "Marshall Islands",
		Code: "MH",
		Timezone: "UTC+12",
		UTC: "UTC+12:00",
		MobileCode: "+692",
	},
	{
		Name: "Martinique",
		Code: "MQ",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+596",
	},
	{
		Name: "Mauritania",
		Code: "MR",
		Timezone: "Greenwich Standard Time",
		UTC: "UTC",
		MobileCode: "+222",
	},
	{
		Name: "Mauritius",
		Code: "MU",
		Timezone: "Mauritius Standard Time",
		UTC: "UTC+04:00",
		MobileCode: "+230",
	},
	{
		Name: "Mayotte",
		Code: "YT",
		Timezone: "E. Africa Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+262",
	},
	{
		Name: "Mexico",
		Code: "MX",
		Timezone: "Central Standard Time (Mexico)",
		UTC: "UTC-06:00",
		MobileCode: "+52",
	},
	{
		Name: "Micronesia",
		Code: "FM",
		Timezone: "West Pacific Standard Time",
		UTC: "UTC+10:00",
		MobileCode: "+691",
	},
	{
		Name: "Moldova",
		Code: "MD",
		Timezone: "GTB Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+373",
	},
	{
		Name: "Monaco",
		Code: "MC",
		Timezone: "W. Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+377",
	},
	{
		Name: "Mongolia",
		Code: "MN",
		Timezone: "Ulaanbaatar Standard Time",
		UTC: "UTC+08:00",
		MobileCode: "+976",
	},
	{
		Name: "Montenegro",
		Code: "ME",
		Timezone: "Central European Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+382",
	},
	{
		Name: "Montserrat",
		Code: "MS",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+1-664",
	},
	{
		Name: "Morocco",
		Code: "MA",
		Timezone: "Morocco Standard Time",
		UTC: "UTC",
		MobileCode: "+212",
	},
	{
		Name: "Mozambique",
		Code: "MZ",
		Timezone: "South Africa Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+258",
	},
	{
		Name: "Myanmar",
		Code: "MM",
		Timezone: "Myanmar Standard Time",
		UTC: "UTC+06:30",
		MobileCode: "+95",
	},
	{
		Name: "Namibia",
		Code: "NA",
		Timezone: "Namibia Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+264",
	},
	{
		Name: "Nauru",
		Code: "NR",
		Timezone: "UTC+12",
		UTC: "UTC+12:00",
		MobileCode: "+674",
	},
	{
		Name: "Nepal",
		Code: "NP",
		Timezone: "Nepal Standard Time",
		UTC: "UTC+05:45",
		MobileCode: "+977",
	},
	{
		Name: "Netherlands",
		Code: "NL",
		Timezone: "W. Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+31",
	},
	{
		Name: "New Caledonia",
		Code: "NC",
		Timezone: "Central Pacific Standard Time",
		UTC: "UTC+11:00",
		MobileCode: "+687",
	},
	{
		Name: "New Zealand",
		Code: "NZ",
		Timezone: "New Zealand Standard Time",
		UTC: "UTC+12:00",
		MobileCode: "+64",
	},
	{
		Name: "Nicaragua",
		Code: "NI",
		Timezone: "Central America Standard Time",
		UTC: "UTC-06:00",
		MobileCode: "+505",
	},
	{
		Name: "Niger",
		Code: "NE",
		Timezone: "W. Central Africa Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+227",
	},
	{
		Name: "Nigeria",
		Code: "NG",
		Timezone: "W. Central Africa Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+234",
	},
	{
		Name: "Niue",
		Code: "NU",
		Timezone: "UTC-11",
		UTC: "UTC-11:00",
		MobileCode: "+683",
	},
	{
		Name: "Norfolk Island",
		Code: "NF",
		Timezone: "Central Pacific Standard Time",
		UTC: "UTC+11:00",
		MobileCode: "+672",
	},
	{
		Name: "North Korea",
		Code: "KP",
		Timezone: "Korea Standard Time",
		UTC: "UTC+09:00",
		MobileCode: "+850",
	},
	{
		Name: "Northern Mariana Islands",
		Code: "MP",
		Timezone: "West Pacific Standard Time",
		UTC: "UTC+10:00",
		MobileCode: "+1-670",
	},
	{
		Name: "Norway",
		Code: "NO",
		Timezone: "W. Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+47",
	},
	{
		Name: "Oman",
		Code: "OM",
		Timezone: "Arabian Standard Time",
		UTC: "UTC+04:00",
		MobileCode: "+968",
	},
	{
		Name: "Pakistan",
		Code: "PK",
		Timezone: "Pakistan Standard Time",
		UTC: "UTC+05:00",
		MobileCode: "+92",
	},
	{
		Name: "Palau",
		Code: "PW",
		Timezone: "Tokyo Standard Time",
		UTC: "UTC+09:00",
		MobileCode: "+680",
	},
	{
		Name: "Palestinian Authority",
		Code: "PS",
		Timezone: "Egypt Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+970",
	},
	{
		Name: "Panama",
		Code: "PA",
		Timezone: "SA Pacific Standard Time",
		UTC: "UTC-05:00",
		MobileCode: "+507",
	},
	{
		Name: "Papua New Guinea",
		Code: "PG",
		Timezone: "West Pacific Standard Time",
		UTC: "UTC+10:00",
		MobileCode: "+675",
	},
	{
		Name: "Paraguay",
		Code: "PY",
		Timezone: "Paraguay Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+595",
	},
	{
		Name: "Peru",
		Code: "PE",
		Timezone: "SA Pacific Standard Time",
		UTC: "UTC-05:00",
		MobileCode: "+51",
	},
	{
		Name: "Philippines",
		Code: "PH",
		Timezone: "Singapore Standard Time",
		UTC: "UTC+08:00",
		MobileCode: "+63",
	},
	{
		Name: "Pitcairn Islands",
		Code: "PN",
		Timezone: "Pacific Standard Time",
		UTC: "UTC-08:00",
		MobileCode: "+870",
	},
	{
		Name: "Poland",
		Code: "PL",
		Timezone: "Central European Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+48",
	},
	{
		Name: "Portugal",
		Code: "PT",
		Timezone: "GMT Standard Time",
		UTC: "UTC",
		MobileCode: "+351",
	},
	{
		Name: "Puerto Rico",
		Code: "PR",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+1-787 and 1-939",
	},
	{
		Name: "Qatar",
		Code: "QA",
		Timezone: "Arab Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+974",
	},
	{
		Name: "Reunion",
		Code: "RE",
		Timezone: "Mauritius Standard Time",
		UTC: "UTC+04:00",
		MobileCode: "+262",
	},
	{
		Name: "Romania",
		Code: "RO",
		Timezone: "GTB Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+40",
	},
	{
		Name: "Russia",
		Code: "RU",
		Timezone: "Russian Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+7",
	},
	{
		Name: "Rwanda",
		Code: "RW",
		Timezone: "South Africa Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+250",
	},
	{
		Name: "Saint Barthélemy",
		Code: "BL",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+590",
	},
	{
		Name: "Saint Helena, Ascension and Tristan da Cunha",
		Code: "SH",
		Timezone: "Greenwich Standard Time",
		UTC: "UTC",
		MobileCode: "+290",
	},
	{
		Name: "Saint Kitts and Nevis",
		Code: "KN",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+1-869",
	},
	{
		Name: "Saint Lucia",
		Code: "LC",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+1-758",
	},
	{
		Name: "Saint Martin (French part)",
		Code: "MF",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+590",
	},
	{
		Name: "Saint Pierre and Miquelon",
		Code: "PM",
		Timezone: "Greenland Standard Time",
		UTC: "UTC-03:00",
		MobileCode: "+508",
	},
	{
		Name: "Saint Vincent and the Grenadines",
		Code: "VC",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+1-784",
	},
	{
		Name: "Samoa",
		Code: "WS",
		Timezone: "Samoa Standard Time",
		UTC: "UTC+13:00",
		MobileCode: "+685",
	},
	{
		Name: "San Marino",
		Code: "SM",
		Timezone: "W. Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+378",
	},
	{
		Name: "São Tomé and Príncipe",
		Code: "ST",
		Timezone: "Greenwich Standard Time",
		UTC: "UTC",
		MobileCode: "+239",
	},
	{
		Name: "Saudi Arabia",
		Code: "SA",
		Timezone: "Arab Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+966",
	},
	{
		Name: "Senegal",
		Code: "SN",
		Timezone: "Greenwich Standard Time",
		UTC: "UTC",
		MobileCode: "+221",
	},
	{
		Name: "Serbia",
		Code: "RS",
		Timezone: "Central Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+381",
	},
	{
		Name: "Seychelles",
		Code: "SC",
		Timezone: "Mauritius Standard Time",
		UTC: "UTC+04:00",
		MobileCode: "+248",
	},
	{
		Name: "Sierra Leone",
		Code: "SL",
		Timezone: "Greenwich Standard Time",
		UTC: "UTC",
		MobileCode: "+232",
	},
	{
		Name: "Singapore",
		Code: "SG",
		Timezone: "Singapore Standard Time",
		UTC: "UTC+08:00",
		MobileCode: "+65",
	},
	{
		Name: "Sint Maarten (Dutch part)",
		Code: "SX",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+599",
	},
	{
		Name: "Slovakia",
		Code: "SK",
		Timezone: "Central Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+421",
	},
	{
		Name: "Slovenia",
		Code: "SI",
		Timezone: "Central Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+386",
	},
	{
		Name: "Solomon Islands",
		Code: "SB",
		Timezone: "Central Pacific Standard Time",
		UTC: "UTC+11:00",
		MobileCode: "+677",
	},
	{
		Name: "Somalia",
		Code: "SO",
		Timezone: "E. Africa Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+252",
	},
	{
		Name: "South Africa",
		Code: "ZA",
		Timezone: "South Africa Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+27",
	},
	{
		Name: "South Georgia and the South Sandwich Islands",
		Code: "GS",
		Timezone: "UTC-02",
		UTC: "UTC-02:00",
		MobileCode: "+",
	},
	{
		Name: "South Sudan",
		Code: "SS",
		Timezone: "E. Africa Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+211",
	},
	{
		Name: "Spain",
		Code: "ES",
		Timezone: "Romance Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+34",
	},
	{
		Name: "Sri Lanka",
		Code: "LK",
		Timezone: "Sri Lanka Standard Time",
		UTC: "UTC+05:30",
		MobileCode: "+94",
	},
	{
		Name: "Sudan",
		Code: "SD",
		Timezone: "E. Africa Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+249",
	},
	{
		Name: "Suriname",
		Code: "SR",
		Timezone: "SA Eastern Standard Time",
		UTC: "UTC-03:00",
		MobileCode: "+597",
	},
	{
		Name: "Svalbard",
		Code: "SJ",
		Timezone: "W. Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+47",
	},
	{
		Name: "Swaziland",
		Code: "SZ",
		Timezone: "South Africa Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+268",
	},
	{
		Name: "Sweden",
		Code: "SE",
		Timezone: "W. Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+46",
	},
	{
		Name: "Switzerland",
		Code: "CH",
		Timezone: "W. Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+41",
	},
	{
		Name: "Syria",
		Code: "SY",
		Timezone: "Syria Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+963",
	},
	{
		Name: "Taiwan",
		Code: "TW",
		Timezone: "Taipei Standard Time",
		UTC: "UTC+08:00",
		MobileCode: "+886",
	},
	{
		Name: "Tajikistan",
		Code: "TJ",
		Timezone: "West Asia Standard Time",
		UTC: "UTC+05:00",
		MobileCode: "+992",
	},
	{
		Name: "Tanzania",
		Code: "TZ",
		Timezone: "E. Africa Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+255",
	},
	{
		Name: "Thailand",
		Code: "TH",
		Timezone: "SE Asia Standard Time",
		UTC: "UTC+07:00",
		MobileCode: "+66",
	},
	{
		Name: "Togo",
		Code: "TG",
		Timezone: "Greenwich Standard Time",
		UTC: "UTC",
		MobileCode: "+228",
	},
	{
		Name: "Tokelau",
		Code: "TK",
		Timezone: "Tonga Standard Time",
		UTC: "UTC+13:00",
		MobileCode: "+690",
	},
	{
		Name: "Tonga",
		Code: "TO",
		Timezone: "Tonga Standard Time",
		UTC: "UTC+13:00",
		MobileCode: "+676",
	},
	{
		Name: "Trinidad and Tobago",
		Code: "TT",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+1-868",
	},
	{
		Name: "Tunisia",
		Code: "TN",
		Timezone: "W. Central Africa Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+216",
	},
	{
		Name: "Turkey",
		Code: "TR",
		Timezone: "Turkey Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+90",
	},
	{
		Name: "Turkmenistan",
		Code: "TM",
		Timezone: "West Asia Standard Time",
		UTC: "UTC+05:00",
		MobileCode: "+993",
	},
	{
		Name: "Turks and Caicos Islands",
		Code: "TC",
		Timezone: "Eastern Standard Time",
		UTC: "UTC-05:00",
		MobileCode: "+1-649",
	},
	{
		Name: "Tuvalu",
		Code: "TV",
		Timezone: "UTC+12",
		UTC: "UTC+12:00",
		MobileCode: "+688",
	},
	{
		Name: "U.S. Minor Outlying Islands",
		Code: "UM",
		Timezone: "UTC-11",
		UTC: "UTC-11:00",
		MobileCode: "+1",
	},
	{
		Name: "Uganda",
		Code: "UG",
		Timezone: "E. Africa Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+256",
	},
	{
		Name: "Ukraine",
		Code: "UA",
		Timezone: "FLE Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+380",
	},
	{
		Name: "United Arab Emirates",
		Code: "AE",
		Timezone: "Arabian Standard Time",
		UTC: "UTC+04:00",
		MobileCode: "+971",
	},
	{
		Name: "United Kingdom",
		Code: "GB",
		Timezone: "GMT Standard Time",
		UTC: "UTC",
		MobileCode: "+44",
	},
	{
		Name: "United States",
		Code: "US",
		Timezone: "Pacific Standard Time",
		UTC: "UTC-08:00",
		MobileCode: "+1",
	},
	{
		Name: "Uruguay",
		Code: "UY",
		Timezone: "Montevideo Standard Time",
		UTC: "UTC-03:00",
		MobileCode: "+598",
	},
	{
		Name: "Uzbekistan",
		Code: "UZ",
		Timezone: "West Asia Standard Time",
		UTC: "UTC+05:00",
		MobileCode: "+998",
	},
	{
		Name: "Vanuatu",
		Code: "VU",
		Timezone: "Central Pacific Standard Time",
		UTC: "UTC+11:00",
		MobileCode: "+678",
	},
	{
		Name: "Vatican City",
		Code: "VA",
		Timezone: "W. Europe Standard Time",
		UTC: "UTC+01:00",
		MobileCode: "+379",
	},
	{
		Name: "Vietnam",
		Code: "VN",
		Timezone: "SE Asia Standard Time",
		UTC: "UTC+07:00",
		MobileCode: "+84",
	},
	{
		Name: "Virgin Islands, U.S.",
		Code: "VI",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+1-340",
	},
	{
		Name: "Virgin Islands, British",
		Code: "VG",
		Timezone: "SA Western Standard Time",
		UTC: "UTC-04:00",
		MobileCode: "+1-284",
	},
	{
		Name: "Wallis and Futuna",
		Code: "WF",
		Timezone: "UTC+12",
		UTC: "UTC+12:00",
		MobileCode: "+681",
	},
	{
		Name: "Yemen",
		Code: "YE",
		Timezone: "Arab Standard Time",
		UTC: "UTC+03:00",
		MobileCode: "+967",
	},
	{
		Name: "Zambia",
		Code: "ZM",
		Timezone: "South Africa Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+260",
	},
	{
		Name: "Zimbabwe",
		Code: "ZW",
		Timezone: "South Africa Standard Time",
		UTC: "UTC+02:00",
		MobileCode: "+263",
	},
];
export const UserZone = {
	Andorra: "Andorra",
	Dubai: "United Arab Emirates",
	Kabul: "Afghanistan",
	Tirane: "Albania",
	Yerevan: "Armenia",
	Casey: "Antarctica",
	Davis: "Antarctica",
	Mawson: "Antarctica",
	Palmer: "Antarctica",
	Rothera: "Antarctica",
	Troll: "Antarctica",
	Vostok: "Antarctica",
	Buenos_Aires: "Argentina",
	Cordoba: "Argentina",
	Salta: "Argentina",
	Jujuy: "Argentina",
	Tucuman: "Argentina",
	Catamarca: "Argentina",
	La_Rioja: "Argentina",
	San_Juan: "Argentina",
	Mendoza: "Argentina",
	San_Luis: "Argentina",
	Rio_Gallegos: "Argentina",
	Ushuaia: "Argentina",
	Pago_Pago: "Samoa (American)",
	Vienna: "Austria",
	Lord_Howe: "Australia",
	Macquarie: "Australia",
	Hobart: "Australia",
	Melbourne: "Australia",
	Sydney: "Australia",
	Broken_Hill: "Australia",
	Brisbane: "Australia",
	Lindeman: "Australia",
	Adelaide: "Australia",
	Darwin: "Australia",
	Perth: "Australia",
	Eucla: "Australia",
	Baku: "Azerbaijan",
	Barbados: "Barbados",
	Dhaka: "Bangladesh",
	Brussels: "Belgium",
	Sofia: "Bulgaria",
	Bermuda: "Bermuda",
	Brunei: "Brunei",
	La_Paz: "Bolivia",
	Noronha: "Brazil",
	Belem: "Brazil",
	Fortaleza: "Brazil",
	Recife: "Brazil",
	Araguaina: "Brazil",
	Maceio: "Brazil",
	Bahia: "Brazil",
	Sao_Paulo: "Brazil",
	Campo_Grande: "Brazil",
	Cuiaba: "Brazil",
	Santarem: "Brazil",
	Porto_Velho: "Brazil",
	Boa_Vista: "Brazil",
	Manaus: "Brazil",
	Eirunepe: "Brazil",
	Rio_Branco: "Brazil",
	Thimphu: "Bhutan",
	Minsk: "Belarus",
	Belize: "Belize",
	St_Johns: "Canada",
	Halifax: "Canada",
	Glace_Bay: "Canada",
	Moncton: "Canada",
	Goose_Bay: "Canada",
	Toronto: "Canada",
	Nipigon: "Canada",
	Thunder_Bay: "Canada",
	Iqaluit: "Canada",
	Pangnirtung: "Canada",
	Winnipeg: "Canada",
	Rainy_River: "Canada",
	Resolute: "Canada",
	Rankin_Inlet: "Canada",
	Regina: "Canada",
	Swift_Current: "Canada",
	Edmonton: "Canada",
	Cambridge_Bay: "Canada",
	Yellowknife: "Canada",
	Inuvik: "Canada",
	Dawson_Creek: "Canada",
	Fort_Nelson: "Canada",
	Whitehorse: "Canada",
	Dawson: "Canada",
	Vancouver: "Canada",
	Cocos: "Cocos (Keeling) Islands",
	Zurich: "Switzerland",
	Abidjan: "Côte d'Ivoire",
	Rarotonga: "Cook Islands",
	Santiago: "Chile",
	Punta_Arenas: "Chile",
	Easter: "Chile",
	Shanghai: "China",
	Urumqi: "China",
	Bogota: "Colombia",
	Costa_Rica: "Costa Rica",
	Havana: "Cuba",
	Cape_Verde: "Cape Verde",
	Christmas: "Christmas Island",
	Nicosia: "Cyprus",
	Famagusta: "Cyprus",
	Prague: "Czech Republic",
	Berlin: "Germany",
	Copenhagen: "Denmark",
	Santo_Domingo: "Dominican Republic",
	Algiers: "Algeria",
	Guayaquil: "Ecuador",
	Galapagos: "Ecuador",
	Tallinn: "Estonia",
	Cairo: "Egypt",
	El_Aaiun: "Western Sahara",
	Madrid: "Spain",
	Ceuta: "Spain",
	Canary: "Spain",
	Helsinki: "Finland",
	Fiji: "Fiji",
	Stanley: "Falkland Islands",
	Chuuk: "Micronesia",
	Pohnpei: "Micronesia",
	Kosrae: "Micronesia",
	Faroe: "Faroe Islands",
	Paris: "France",
	London: "Britain (UK)",
	Tbilisi: "Georgia",
	Cayenne: "French Guiana",
	Gibraltar: "Gibraltar",
	Nuuk: "Greenland",
	Danmarkshavn: "Greenland",
	Scoresbysund: "Greenland",
	Thule: "Greenland",
	Athens: "Greece",
	South_Georgia: "South Georgia & the South Sandwich Islands",
	Guatemala: "Guatemala",
	Guam: "Guam",
	Bissau: "Guinea-Bissau",
	Guyana: "Guyana",
	Hong_Kong: "Hong Kong",
	Tegucigalpa: "Honduras",
	"Port-au-Prince": "Haiti",
	Budapest: "Hungary",
	Jakarta: "Indonesia",
	Pontianak: "Indonesia",
	Makassar: "Indonesia",
	Jayapura: "Indonesia",
	Dublin: "Ireland",
	Jerusalem: "Israel",
	Kolkata: "India",
	Chagos: "British Indian Ocean Territory",
	Baghdad: "Iraq",
	Tehran: "Iran",
	Reykjavik: "Iceland",
	Rome: "Italy",
	Jamaica: "Jamaica",
	Amman: "Jordan",
	Tokyo: "Japan",
	Nairobi: "Kenya",
	Bishkek: "Kyrgyzstan",
	Tarawa: "Kiribati",
	Kanton: "Kiribati",
	Kiritimati: "Kiribati",
	Pyongyang: "Korea (North)",
	Seoul: "Korea (South)",
	Almaty: "Kazakhstan",
	Qyzylorda: "Kazakhstan",
	Qostanay: "Kazakhstan",
	Aqtobe: "Kazakhstan",
	Aqtau: "Kazakhstan",
	Atyrau: "Kazakhstan",
	Oral: "Kazakhstan",
	Beirut: "Lebanon",
	Colombo: "Sri Lanka",
	Monrovia: "Liberia",
	Vilnius: "Lithuania",
	Luxembourg: "Luxembourg",
	Riga: "Latvia",
	Tripoli: "Libya",
	Casablanca: "Morocco",
	Monaco: "Monaco",
	Chisinau: "Moldova",
	Majuro: "Marshall Islands",
	Kwajalein: "Marshall Islands",
	Yangon: "Myanmar (Burma)",
	Ulaanbaatar: "Mongolia",
	Hovd: "Mongolia",
	Choibalsan: "Mongolia",
	Macau: "Macau",
	Martinique: "Martinique",
	Malta: "Malta",
	Mauritius: "Mauritius",
	Maldives: "Maldives",
	Mexico_City: "Mexico",
	Cancun: "Mexico",
	Merida: "Mexico",
	Monterrey: "Mexico",
	Matamoros: "Mexico",
	Mazatlan: "Mexico",
	Chihuahua: "Mexico",
	Ojinaga: "Mexico",
	Hermosillo: "Mexico",
	Tijuana: "Mexico",
	Bahia_Banderas: "Mexico",
	Kuala_Lumpur: "Malaysia",
	Kuching: "Malaysia",
	Maputo: "Mozambique",
	Windhoek: "Namibia",
	Noumea: "New Caledonia",
	Norfolk: "Norfolk Island",
	Lagos: "Nigeria",
	Managua: "Nicaragua",
	Amsterdam: "Netherlands",
	Oslo: "Norway",
	Kathmandu: "Nepal",
	Nauru: "Nauru",
	Niue: "Niue",
	Auckland: "New Zealand",
	Chatham: "New Zealand",
	Panama: "Panama",
	Lima: "Peru",
	Tahiti: "French Polynesia",
	Marquesas: "French Polynesia",
	Gambier: "French Polynesia",
	Port_Moresby: "Papua New Guinea",
	Bougainville: "Papua New Guinea",
	Manila: "Philippines",
	Karachi: "Pakistan",
	Warsaw: "Poland",
	Miquelon: "St Pierre & Miquelon",
	Pitcairn: "Pitcairn",
	Puerto_Rico: "Puerto Rico",
	Gaza: "Palestine",
	Hebron: "Palestine",
	Lisbon: "Portugal",
	Madeira: "Portugal",
	Azores: "Portugal",
	Palau: "Palau",
	Asuncion: "Paraguay",
	Qatar: "Qatar",
	Reunion: "Réunion",
	Bucharest: "Romania",
	Belgrade: "Serbia",
	Kaliningrad: "Russia",
	Moscow: "Russia",
	Simferopol: "Russia",
	Kirov: "Russia",
	Volgograd: "Russia",
	Astrakhan: "Russia",
	Saratov: "Russia",
	Ulyanovsk: "Russia",
	Samara: "Russia",
	Yekaterinburg: "Russia",
	Omsk: "Russia",
	Novosibirsk: "Russia",
	Barnaul: "Russia",
	Tomsk: "Russia",
	Novokuznetsk: "Russia",
	Krasnoyarsk: "Russia",
	Irkutsk: "Russia",
	Chita: "Russia",
	Yakutsk: "Russia",
	Khandyga: "Russia",
	Vladivostok: "Russia",
	"Ust-Nera": "Russia",
	Magadan: "Russia",
	Sakhalin: "Russia",
	Srednekolymsk: "Russia",
	Kamchatka: "Russia",
	Anadyr: "Russia",
	Riyadh: "Saudi Arabia",
	Guadalcanal: "Solomon Islands",
	Mahe: "Seychelles",
	Khartoum: "Sudan",
	Stockholm: "Sweden",
	Singapore: "Singapore",
	Paramaribo: "Suriname",
	Juba: "South Sudan",
	Sao_Tome: "Sao Tome & Principe",
	El_Salvador: "El Salvador",
	Damascus: "Syria",
	Grand_Turk: "Turks & Caicos Is",
	Ndjamena: "Chad",
	Kerguelen: "French Southern & Antarctic Lands",
	Bangkok: "Thailand",
	Dushanbe: "Tajikistan",
	Fakaofo: "Tokelau",
	Dili: "East Timor",
	Ashgabat: "Turkmenistan",
	Tunis: "Tunisia",
	Tongatapu: "Tonga",
	Istanbul: "Turkey",
	Funafuti: "Tuvalu",
	Taipei: "Taiwan",
	Kiev: "Ukraine",
	Uzhgorod: "Ukraine",
	Zaporozhye: "Ukraine",
	Wake: "US minor outlying islands",
	New_York: "United States",
	Detroit: "United States",
	Louisville: "United States",
	Monticello: "United States",
	Indianapolis: "United States",
	Vincennes: "United States",
	Winamac: "United States",
	Marengo: "United States",
	Petersburg: "United States",
	Vevay: "United States",
	Chicago: "United States",
	Tell_City: "United States",
	Knox: "United States",
	Menominee: "United States",
	Center: "United States",
	New_Salem: "United States",
	Beulah: "United States",
	Denver: "United States",
	Boise: "United States",
	Phoenix: "United States",
	Los_Angeles: "United States",
	Anchorage: "United States",
	Juneau: "United States",
	Sitka: "United States",
	Metlakatla: "United States",
	Yakutat: "United States",
	Nome: "United States",
	Adak: "United States",
	Honolulu: "United States",
	Montevideo: "Uruguay",
	Samarkand: "Uzbekistan",
	Tashkent: "Uzbekistan",
	Caracas: "Venezuela",
	Ho_Chi_Minh: "Vietnam",
	Efate: "Vanuatu",
	Wallis: "Wallis & Futuna",
	Apia: "Samoa (western)",
	Johannesburg: "South Africa",
	Antigua: "Antigua & Barbuda",
	Anguilla: "Anguilla",
	Luanda: "Angola",
	McMurdo: "Antarctica",
	DumontDUrville: "Antarctica",
	Syowa: "Antarctica",
	Aruba: "Aruba",
	Mariehamn: "Åland Islands",
	Sarajevo: "Bosnia & Herzegovina",
	Ouagadougou: "Burkina Faso",
	Bahrain: "Bahrain",
	Bujumbura: "Burundi",
	"Porto-Novo": "Benin",
	St_Barthelemy: "St Barthelemy",
	Kralendijk: "Caribbean NL",
	Nassau: "Bahamas",
	Gaborone: "Botswana",
	"Blanc-Sablon": "Canada",
	Atikokan: "Canada",
	Creston: "Canada",
	Kinshasa: "Congo (Dem. Rep.)",
	Lubumbashi: "Congo (Dem. Rep.)",
	Bangui: "Central African Rep.",
	Brazzaville: "Congo (Rep.)",
	Douala: "Cameroon",
	Curacao: "Curaçao",
	Busingen: "Germany",
	Djibouti: "Djibouti",
	Dominica: "Dominica",
	Asmara: "Eritrea",
	Addis_Ababa: "Ethiopia",
	Libreville: "Gabon",
	Grenada: "Grenada",
	Guernsey: "Guernsey",
	Accra: "Ghana",
	Banjul: "Gambia",
	Conakry: "Guinea",
	Guadeloupe: "Guadeloupe",
	Malabo: "Equatorial Guinea",
	Zagreb: "Croatia",
	Isle_of_Man: "Isle of Man",
	Jersey: "Jersey",
	Phnom_Penh: "Cambodia",
	Comoro: "Comoros",
	St_Kitts: "St Kitts & Nevis",
	Kuwait: "Kuwait",
	Cayman: "Cayman Islands",
	Vientiane: "Laos",
	St_Lucia: "St Lucia",
	Vaduz: "Liechtenstein",
	Maseru: "Lesotho",
	Podgorica: "Montenegro",
	Marigot: "St Martin (French)",
	Antananarivo: "Madagascar",
	Skopje: "North Macedonia",
	Bamako: "Mali",
	Saipan: "Northern Mariana Islands",
	Nouakchott: "Mauritania",
	Montserrat: "Montserrat",
	Blantyre: "Malawi",
	Niamey: "Niger",
	Muscat: "Oman",
	Kigali: "Rwanda",
	St_Helena: "St Helena",
	Ljubljana: "Slovenia",
	Longyearbyen: "Svalbard & Jan Mayen",
	Bratislava: "Slovakia",
	Freetown: "Sierra Leone",
	San_Marino: "San Marino",
	Dakar: "Senegal",
	Mogadishu: "Somalia",
	Lower_Princes: "St Maarten (Dutch)",
	Mbabane: "Eswatini (Swaziland)",
	Lome: "Togo",
	Port_of_Spain: "Trinidad & Tobago",
	Dar_es_Salaam: "Tanzania",
	Kampala: "Uganda",
	Midway: "US minor outlying islands",
	Vatican: "Vatican City",
	St_Vincent: "St Vincent",
	Tortola: "Virgin Islands (UK)",
	St_Thomas: "Virgin Islands (US)",
	Aden: "Yemen",
	Mayotte: "Mayotte",
	Lusaka: "Zambia",
	Harare: "Zimbabwe",
};
export const CountryList = [
	{
		countryCode: "AD",
		countryName: "Andorra",
		currencyCode: "EUR",
		population: "84000",
		capital: "Andorra la Vella",
		continentName: "Europe",
	},
	{
		countryCode: "AE",
		countryName: "United Arab Emirates",
		currencyCode: "AED",
		population: "4975593",
		capital: "Abu Dhabi",
		continentName: "Asia",
	},
	{
		countryCode: "AF",
		countryName: "Afghanistan",
		currencyCode: "AFN",
		population: "29121286",
		capital: "Kabul",
		continentName: "Asia",
	},
	{
		countryCode: "AG",
		countryName: "Antigua and Barbuda",
		currencyCode: "XCD",
		population: "86754",
		capital: "St. John's",
		continentName: "North America",
	},
	{
		countryCode: "AI",
		countryName: "Anguilla",
		currencyCode: "XCD",
		population: "13254",
		capital: "The Valley",
		continentName: "North America",
	},
	{
		countryCode: "AL",
		countryName: "Albania",
		currencyCode: "ALL",
		population: "2986952",
		capital: "Tirana",
		continentName: "Europe",
	},
	{
		countryCode: "AM",
		countryName: "Armenia",
		currencyCode: "AMD",
		population: "2968000",
		capital: "Yerevan",
		continentName: "Asia",
	},
	{
		countryCode: "AO",
		countryName: "Angola",
		currencyCode: "AOA",
		population: "13068161",
		capital: "Luanda",
		continentName: "Africa",
	},
	{
		countryCode: "AQ",
		countryName: "Antarctica",
		currencyCode: "",
		population: "0",
		capital: "",
		continentName: "Antarctica",
	},
	{
		countryCode: "AR",
		countryName: "Argentina",
		currencyCode: "ARS",
		population: "41343201",
		capital: "Buenos Aires",
		continentName: "South America",
	},
	{
		countryCode: "AS",
		countryName: "American Samoa",
		currencyCode: "USD",
		population: "57881",
		capital: "Pago Pago",
		continentName: "Oceania",
	},
	{
		countryCode: "AT",
		countryName: "Austria",
		currencyCode: "EUR",
		population: "8205000",
		capital: "Vienna",
		continentName: "Europe",
	},
	{
		countryCode: "AU",
		countryName: "Australia",
		currencyCode: "AUD",
		population: "21515754",
		capital: "Canberra",
		continentName: "Oceania",
	},
	{
		countryCode: "AW",
		countryName: "Aruba",
		currencyCode: "AWG",
		population: "71566",
		capital: "Oranjestad",
		continentName: "North America",
	},
	{
		countryCode: "AX",
		countryName: "Åland",
		currencyCode: "EUR",
		population: "26711",
		capital: "Mariehamn",
		continentName: "Europe",
	},
	{
		countryCode: "AZ",
		countryName: "Azerbaijan",
		currencyCode: "AZN",
		population: "8303512",
		capital: "Baku",
		continentName: "Asia",
	},
	{
		countryCode: "BA",
		countryName: "Bosnia and Herzegovina",
		currencyCode: "BAM",
		population: "4590000",
		capital: "Sarajevo",
		continentName: "Europe",
	},
	{
		countryCode: "BB",
		countryName: "Barbados",
		currencyCode: "BBD",
		population: "285653",
		capital: "Bridgetown",
		continentName: "North America",
	},
	{
		countryCode: "BD",
		countryName: "Bangladesh",
		currencyCode: "BDT",
		population: "156118464",
		capital: "Dhaka",
		continentName: "Asia",
	},
	{
		countryCode: "BE",
		countryName: "Belgium",
		currencyCode: "EUR",
		population: "10403000",
		capital: "Brussels",
		continentName: "Europe",
	},
	{
		countryCode: "BF",
		countryName: "Burkina Faso",
		currencyCode: "XOF",
		population: "16241811",
		capital: "Ouagadougou",
		continentName: "Africa",
	},
	{
		countryCode: "BG",
		countryName: "Bulgaria",
		currencyCode: "BGN",
		population: "7148785",
		capital: "Sofia",
		continentName: "Europe",
	},
	{
		countryCode: "BH",
		countryName: "Bahrain",
		currencyCode: "BHD",
		population: "738004",
		capital: "Manama",
		continentName: "Asia",
	},
	{
		countryCode: "BI",
		countryName: "Burundi",
		currencyCode: "BIF",
		population: "9863117",
		capital: "Bujumbura",
		continentName: "Africa",
	},
	{
		countryCode: "BJ",
		countryName: "Benin",
		currencyCode: "XOF",
		population: "9056010",
		capital: "Porto-Novo",
		continentName: "Africa",
	},
	{
		countryCode: "BL",
		countryName: "Saint Barthélemy",
		currencyCode: "EUR",
		population: "8450",
		capital: "Gustavia",
		continentName: "North America",
	},
	{
		countryCode: "BM",
		countryName: "Bermuda",
		currencyCode: "BMD",
		population: "65365",
		capital: "Hamilton",
		continentName: "North America",
	},
	{
		countryCode: "BN",
		countryName: "Brunei",
		currencyCode: "BND",
		population: "395027",
		capital: "Bandar Seri Begawan",
		continentName: "Asia",
	},
	{
		countryCode: "BO",
		countryName: "Bolivia",
		currencyCode: "BOB",
		population: "9947418",
		capital: "Sucre",
		continentName: "South America",
	},
	{
		countryCode: "BQ",
		countryName: "Bonaire",
		currencyCode: "USD",
		population: "18012",
		capital: "Kralendijk",
		continentName: "North America",
	},
	{
		countryCode: "BR",
		countryName: "Brazil",
		currencyCode: "BRL",
		population: "201103330",
		capital: "Brasília",
		continentName: "South America",
	},
	{
		countryCode: "BS",
		countryName: "Bahamas",
		currencyCode: "BSD",
		population: "301790",
		capital: "Nassau",
		continentName: "North America",
	},
	{
		countryCode: "BT",
		countryName: "Bhutan",
		currencyCode: "BTN",
		population: "699847",
		capital: "Thimphu",
		continentName: "Asia",
	},
	{
		countryCode: "BV",
		countryName: "Bouvet Island",
		currencyCode: "NOK",
		population: "0",
		capital: "",
		continentName: "Antarctica",
	},
	{
		countryCode: "BW",
		countryName: "Botswana",
		currencyCode: "BWP",
		population: "2029307",
		capital: "Gaborone",
		continentName: "Africa",
	},
	{
		countryCode: "BY",
		countryName: "Belarus",
		currencyCode: "BYR",
		population: "9685000",
		capital: "Minsk",
		continentName: "Europe",
	},
	{
		countryCode: "BZ",
		countryName: "Belize",
		currencyCode: "BZD",
		population: "314522",
		capital: "Belmopan",
		continentName: "North America",
	},
	{
		countryCode: "CA",
		countryName: "Canada",
		currencyCode: "CAD",
		population: "33679000",
		capital: "Ottawa",
		continentName: "North America",
	},
	{
		countryCode: "CC",
		countryName: "Cocos [Keeling] Islands",
		currencyCode: "AUD",
		population: "628",
		capital: "West Island",
		continentName: "Asia",
	},
	{
		countryCode: "CD",
		countryName: "Democratic Republic of the Congo",
		currencyCode: "CDF",
		population: "70916439",
		capital: "Kinshasa",
		continentName: "Africa",
	},
	{
		countryCode: "CF",
		countryName: "Central African Republic",
		currencyCode: "XAF",
		population: "4844927",
		capital: "Bangui",
		continentName: "Africa",
	},
	{
		countryCode: "CG",
		countryName: "Republic of the Congo",
		currencyCode: "XAF",
		population: "3039126",
		capital: "Brazzaville",
		continentName: "Africa",
	},
	{
		countryCode: "CH",
		countryName: "Switzerland",
		currencyCode: "CHF",
		population: "7581000",
		capital: "Bern",
		continentName: "Europe",
	},
	{
		countryCode: "CI",
		countryName: "Ivory Coast",
		currencyCode: "XOF",
		population: "21058798",
		capital: "Yamoussoukro",
		continentName: "Africa",
	},
	{
		countryCode: "CK",
		countryName: "Cook Islands",
		currencyCode: "NZD",
		population: "21388",
		capital: "Avarua",
		continentName: "Oceania",
	},
	{
		countryCode: "CL",
		countryName: "Chile",
		currencyCode: "CLP",
		population: "16746491",
		capital: "Santiago",
		continentName: "South America",
	},
	{
		countryCode: "CM",
		countryName: "Cameroon",
		currencyCode: "XAF",
		population: "19294149",
		capital: "Yaoundé",
		continentName: "Africa",
	},
	{
		countryCode: "CN",
		countryName: "China",
		currencyCode: "CNY",
		population: "1330044000",
		capital: "Beijing",
		continentName: "Asia",
	},
	{
		countryCode: "CO",
		countryName: "Colombia",
		currencyCode: "COP",
		population: "47790000",
		capital: "Bogotá",
		continentName: "South America",
	},
	{
		countryCode: "CR",
		countryName: "Costa Rica",
		currencyCode: "CRC",
		population: "4516220",
		capital: "San José",
		continentName: "North America",
	},
	{
		countryCode: "CU",
		countryName: "Cuba",
		currencyCode: "CUP",
		population: "11423000",
		capital: "Havana",
		continentName: "North America",
	},
	{
		countryCode: "CV",
		countryName: "Cape Verde",
		currencyCode: "CVE",
		population: "508659",
		capital: "Praia",
		continentName: "Africa",
	},
	{
		countryCode: "CW",
		countryName: "Curacao",
		currencyCode: "ANG",
		population: "141766",
		capital: "Willemstad",
		continentName: "North America",
	},
	{
		countryCode: "CX",
		countryName: "Christmas Island",
		currencyCode: "AUD",
		population: "1500",
		capital: "Flying Fish Cove",
		continentName: "Asia",
	},
	{
		countryCode: "CY",
		countryName: "Cyprus",
		currencyCode: "EUR",
		population: "1102677",
		capital: "Nicosia",
		continentName: "Europe",
	},
	{
		countryCode: "CZ",
		countryName: "Czechia",
		currencyCode: "CZK",
		population: "10476000",
		capital: "Prague",
		continentName: "Europe",
	},
	{
		countryCode: "DE",
		countryName: "Germany",
		currencyCode: "EUR",
		population: "81802257",
		capital: "Berlin",
		continentName: "Europe",
	},
	{
		countryCode: "DJ",
		countryName: "Djibouti",
		currencyCode: "DJF",
		population: "740528",
		capital: "Djibouti",
		continentName: "Africa",
	},
	{
		countryCode: "DK",
		countryName: "Denmark",
		currencyCode: "DKK",
		population: "5484000",
		capital: "Copenhagen",
		continentName: "Europe",
	},
	{
		countryCode: "DM",
		countryName: "Dominica",
		currencyCode: "XCD",
		population: "72813",
		capital: "Roseau",
		continentName: "North America",
	},
	{
		countryCode: "DO",
		countryName: "Dominican Republic",
		currencyCode: "DOP",
		population: "9823821",
		capital: "Santo Domingo",
		continentName: "North America",
	},
	{
		countryCode: "DZ",
		countryName: "Algeria",
		currencyCode: "DZD",
		population: "34586184",
		capital: "Algiers",
		continentName: "Africa",
	},
	{
		countryCode: "EC",
		countryName: "Ecuador",
		currencyCode: "USD",
		population: "14790608",
		capital: "Quito",
		continentName: "South America",
	},
	{
		countryCode: "EE",
		countryName: "Estonia",
		currencyCode: "EUR",
		population: "1291170",
		capital: "Tallinn",
		continentName: "Europe",
	},
	{
		countryCode: "EG",
		countryName: "Egypt",
		currencyCode: "EGP",
		population: "80471869",
		capital: "Cairo",
		continentName: "Africa",
	},
	{
		countryCode: "EH",
		countryName: "Western Sahara",
		currencyCode: "MAD",
		population: "273008",
		capital: "Laâyoune / El Aaiún",
		continentName: "Africa",
	},
	{
		countryCode: "ER",
		countryName: "Eritrea",
		currencyCode: "ERN",
		population: "5792984",
		capital: "Asmara",
		continentName: "Africa",
	},
	{
		countryCode: "ES",
		countryName: "Spain",
		currencyCode: "EUR",
		population: "46505963",
		capital: "Madrid",
		continentName: "Europe",
	},
	{
		countryCode: "ET",
		countryName: "Ethiopia",
		currencyCode: "ETB",
		population: "88013491",
		capital: "Addis Ababa",
		continentName: "Africa",
	},
	{
		countryCode: "FI",
		countryName: "Finland",
		currencyCode: "EUR",
		population: "5244000",
		capital: "Helsinki",
		continentName: "Europe",
	},
	{
		countryCode: "FJ",
		countryName: "Fiji",
		currencyCode: "FJD",
		population: "875983",
		capital: "Suva",
		continentName: "Oceania",
	},
	{
		countryCode: "FK",
		countryName: "Falkland Islands",
		currencyCode: "FKP",
		population: "2638",
		capital: "Stanley",
		continentName: "South America",
	},
	{
		countryCode: "FM",
		countryName: "Micronesia",
		currencyCode: "USD",
		population: "107708",
		capital: "Palikir",
		continentName: "Oceania",
	},
	{
		countryCode: "FO",
		countryName: "Faroe Islands",
		currencyCode: "DKK",
		population: "48228",
		capital: "Tórshavn",
		continentName: "Europe",
	},
	{
		countryCode: "FR",
		countryName: "France",
		currencyCode: "EUR",
		population: "64768389",
		capital: "Paris",
		continentName: "Europe",
	},
	{
		countryCode: "GA",
		countryName: "Gabon",
		currencyCode: "XAF",
		population: "1545255",
		capital: "Libreville",
		continentName: "Africa",
	},
	{
		countryCode: "GB",
		countryName: "United Kingdom",
		currencyCode: "GBP",
		population: "62348447",
		capital: "London",
		continentName: "Europe",
	},
	{
		countryCode: "GD",
		countryName: "Grenada",
		currencyCode: "XCD",
		population: "107818",
		capital: "St. George's",
		continentName: "North America",
	},
	{
		countryCode: "GE",
		countryName: "Georgia",
		currencyCode: "GEL",
		population: "4630000",
		capital: "Tbilisi",
		continentName: "Asia",
	},
	{
		countryCode: "GF",
		countryName: "French Guiana",
		currencyCode: "EUR",
		population: "195506",
		capital: "Cayenne",
		continentName: "South America",
	},
	{
		countryCode: "GG",
		countryName: "Guernsey",
		currencyCode: "GBP",
		population: "65228",
		capital: "St Peter Port",
		continentName: "Europe",
	},
	{
		countryCode: "GH",
		countryName: "Ghana",
		currencyCode: "GHS",
		population: "24339838",
		capital: "Accra",
		continentName: "Africa",
	},
	{
		countryCode: "GI",
		countryName: "Gibraltar",
		currencyCode: "GIP",
		population: "27884",
		capital: "Gibraltar",
		continentName: "Europe",
	},
	{
		countryCode: "GL",
		countryName: "Greenland",
		currencyCode: "DKK",
		population: "56375",
		capital: "Nuuk",
		continentName: "North America",
	},
	{
		countryCode: "GM",
		countryName: "Gambia",
		currencyCode: "GMD",
		population: "1593256",
		capital: "Bathurst",
		continentName: "Africa",
	},
	{
		countryCode: "GN",
		countryName: "Guinea",
		currencyCode: "GNF",
		population: "10324025",
		capital: "Conakry",
		continentName: "Africa",
	},
	{
		countryCode: "GP",
		countryName: "Guadeloupe",
		currencyCode: "EUR",
		population: "443000",
		capital: "Basse-Terre",
		continentName: "North America",
	},
	{
		countryCode: "GQ",
		countryName: "Equatorial Guinea",
		currencyCode: "XAF",
		population: "1014999",
		capital: "Malabo",
		continentName: "Africa",
	},
	{
		countryCode: "GR",
		countryName: "Greece",
		currencyCode: "EUR",
		population: "11000000",
		capital: "Athens",
		continentName: "Europe",
	},
	{
		countryCode: "GS",
		countryName: "South Georgia and the South Sandwich Islands",
		currencyCode: "GBP",
		population: "30",
		capital: "Grytviken",
		continentName: "Antarctica",
	},
	{
		countryCode: "GT",
		countryName: "Guatemala",
		currencyCode: "GTQ",
		population: "13550440",
		capital: "Guatemala City",
		continentName: "North America",
	},
	{
		countryCode: "GU",
		countryName: "Guam",
		currencyCode: "USD",
		population: "159358",
		capital: "Hagåtña",
		continentName: "Oceania",
	},
	{
		countryCode: "GW",
		countryName: "Guinea-Bissau",
		currencyCode: "XOF",
		population: "1565126",
		capital: "Bissau",
		continentName: "Africa",
	},
	{
		countryCode: "GY",
		countryName: "Guyana",
		currencyCode: "GYD",
		population: "748486",
		capital: "Georgetown",
		continentName: "South America",
	},
	{
		countryCode: "HK",
		countryName: "Hong Kong",
		currencyCode: "HKD",
		population: "6898686",
		capital: "Hong Kong",
		continentName: "Asia",
	},
	{
		countryCode: "HM",
		countryName: "Heard Island and McDonald Islands",
		currencyCode: "AUD",
		population: "0",
		capital: "",
		continentName: "Antarctica",
	},
	{
		countryCode: "HN",
		countryName: "Honduras",
		currencyCode: "HNL",
		population: "7989415",
		capital: "Tegucigalpa",
		continentName: "North America",
	},
	{
		countryCode: "HR",
		countryName: "Croatia",
		currencyCode: "HRK",
		population: "4284889",
		capital: "Zagreb",
		continentName: "Europe",
	},
	{
		countryCode: "HT",
		countryName: "Haiti",
		currencyCode: "HTG",
		population: "9648924",
		capital: "Port-au-Prince",
		continentName: "North America",
	},
	{
		countryCode: "HU",
		countryName: "Hungary",
		currencyCode: "HUF",
		population: "9982000",
		capital: "Budapest",
		continentName: "Europe",
	},
	{
		countryCode: "ID",
		countryName: "Indonesia",
		currencyCode: "IDR",
		population: "242968342",
		capital: "Jakarta",
		continentName: "Asia",
	},
	{
		countryCode: "IE",
		countryName: "Ireland",
		currencyCode: "EUR",
		population: "4622917",
		capital: "Dublin",
		continentName: "Europe",
	},
	{
		countryCode: "IL",
		countryName: "Israel",
		currencyCode: "ILS",
		population: "7353985",
		capital: "",
		continentName: "Asia",
	},
	{
		countryCode: "IM",
		countryName: "Isle of Man",
		currencyCode: "GBP",
		population: "75049",
		capital: "Douglas",
		continentName: "Europe",
	},
	{
		countryCode: "IN",
		countryName: "India",
		currencyCode: "INR",
		population: "1173108018",
		capital: "New Delhi",
		continentName: "Asia",
	},
	{
		countryCode: "IO",
		countryName: "British Indian Ocean Territory",
		currencyCode: "USD",
		population: "4000",
		capital: "",
		continentName: "Asia",
	},
	{
		countryCode: "IQ",
		countryName: "Iraq",
		currencyCode: "IQD",
		population: "29671605",
		capital: "Baghdad",
		continentName: "Asia",
	},
	{
		countryCode: "IR",
		countryName: "Iran",
		currencyCode: "IRR",
		population: "76923300",
		capital: "Tehran",
		continentName: "Asia",
	},
	{
		countryCode: "IS",
		countryName: "Iceland",
		currencyCode: "ISK",
		population: "308910",
		capital: "Reykjavik",
		continentName: "Europe",
	},
	{
		countryCode: "IT",
		countryName: "Italy",
		currencyCode: "EUR",
		population: "60340328",
		capital: "Rome",
		continentName: "Europe",
	},
	{
		countryCode: "JE",
		countryName: "Jersey",
		currencyCode: "GBP",
		population: "90812",
		capital: "Saint Helier",
		continentName: "Europe",
	},
	{
		countryCode: "JM",
		countryName: "Jamaica",
		currencyCode: "JMD",
		population: "2847232",
		capital: "Kingston",
		continentName: "North America",
	},
	{
		countryCode: "JO",
		countryName: "Jordan",
		currencyCode: "JOD",
		population: "6407085",
		capital: "Amman",
		continentName: "Asia",
	},
	{
		countryCode: "JP",
		countryName: "Japan",
		currencyCode: "JPY",
		population: "127288000",
		capital: "Tokyo",
		continentName: "Asia",
	},
	{
		countryCode: "KE",
		countryName: "Kenya",
		currencyCode: "KES",
		population: "40046566",
		capital: "Nairobi",
		continentName: "Africa",
	},
	{
		countryCode: "KG",
		countryName: "Kyrgyzstan",
		currencyCode: "KGS",
		population: "5776500",
		capital: "Bishkek",
		continentName: "Asia",
	},
	{
		countryCode: "KH",
		countryName: "Cambodia",
		currencyCode: "KHR",
		population: "14453680",
		capital: "Phnom Penh",
		continentName: "Asia",
	},
	{
		countryCode: "KI",
		countryName: "Kiribati",
		currencyCode: "AUD",
		population: "92533",
		capital: "Tarawa",
		continentName: "Oceania",
	},
	{
		countryCode: "KM",
		countryName: "Comoros",
		currencyCode: "KMF",
		population: "773407",
		capital: "Moroni",
		continentName: "Africa",
	},
	{
		countryCode: "KN",
		countryName: "Saint Kitts and Nevis",
		currencyCode: "XCD",
		population: "51134",
		capital: "Basseterre",
		continentName: "North America",
	},
	{
		countryCode: "KP",
		countryName: "North Korea",
		currencyCode: "KPW",
		population: "22912177",
		capital: "Pyongyang",
		continentName: "Asia",
	},
	{
		countryCode: "KR",
		countryName: "South Korea",
		currencyCode: "KRW",
		population: "48422644",
		capital: "Seoul",
		continentName: "Asia",
	},
	{
		countryCode: "KW",
		countryName: "Kuwait",
		currencyCode: "KWD",
		population: "2789132",
		capital: "Kuwait City",
		continentName: "Asia",
	},
	{
		countryCode: "KY",
		countryName: "Cayman Islands",
		currencyCode: "KYD",
		population: "44270",
		capital: "George Town",
		continentName: "North America",
	},
	{
		countryCode: "KZ",
		countryName: "Kazakhstan",
		currencyCode: "KZT",
		population: "15340000",
		capital: "Astana",
		continentName: "Asia",
	},
	{
		countryCode: "LA",
		countryName: "Laos",
		currencyCode: "LAK",
		population: "6368162",
		capital: "Vientiane",
		continentName: "Asia",
	},
	{
		countryCode: "LB",
		countryName: "Lebanon",
		currencyCode: "LBP",
		population: "4125247",
		capital: "Beirut",
		continentName: "Asia",
	},
	{
		countryCode: "LC",
		countryName: "Saint Lucia",
		currencyCode: "XCD",
		population: "160922",
		capital: "Castries",
		continentName: "North America",
	},
	{
		countryCode: "LI",
		countryName: "Liechtenstein",
		currencyCode: "CHF",
		population: "35000",
		capital: "Vaduz",
		continentName: "Europe",
	},
	{
		countryCode: "LK",
		countryName: "Sri Lanka",
		currencyCode: "LKR",
		population: "21513990",
		capital: "Colombo",
		continentName: "Asia",
	},
	{
		countryCode: "LR",
		countryName: "Liberia",
		currencyCode: "LRD",
		population: "3685076",
		capital: "Monrovia",
		continentName: "Africa",
	},
	{
		countryCode: "LS",
		countryName: "Lesotho",
		currencyCode: "LSL",
		population: "1919552",
		capital: "Maseru",
		continentName: "Africa",
	},
	{
		countryCode: "LT",
		countryName: "Lithuania",
		currencyCode: "EUR",
		population: "2944459",
		capital: "Vilnius",
		continentName: "Europe",
	},
	{
		countryCode: "LU",
		countryName: "Luxembourg",
		currencyCode: "EUR",
		population: "497538",
		capital: "Luxembourg",
		continentName: "Europe",
	},
	{
		countryCode: "LV",
		countryName: "Latvia",
		currencyCode: "EUR",
		population: "2217969",
		capital: "Riga",
		continentName: "Europe",
	},
	{
		countryCode: "LY",
		countryName: "Libya",
		currencyCode: "LYD",
		population: "6461454",
		capital: "Tripoli",
		continentName: "Africa",
	},
	{
		countryCode: "MA",
		countryName: "Morocco",
		currencyCode: "MAD",
		population: "33848242",
		capital: "Rabat",
		continentName: "Africa",
	},
	{
		countryCode: "MC",
		countryName: "Monaco",
		currencyCode: "EUR",
		population: "32965",
		capital: "Monaco",
		continentName: "Europe",
	},
	{
		countryCode: "MD",
		countryName: "Moldova",
		currencyCode: "MDL",
		population: "4324000",
		capital: "Chişinău",
		continentName: "Europe",
	},
	{
		countryCode: "ME",
		countryName: "Montenegro",
		currencyCode: "EUR",
		population: "666730",
		capital: "Podgorica",
		continentName: "Europe",
	},
	{
		countryCode: "MF",
		countryName: "Saint Martin",
		currencyCode: "EUR",
		population: "35925",
		capital: "Marigot",
		continentName: "North America",
	},
	{
		countryCode: "MG",
		countryName: "Madagascar",
		currencyCode: "MGA",
		population: "21281844",
		capital: "Antananarivo",
		continentName: "Africa",
	},
	{
		countryCode: "MH",
		countryName: "Marshall Islands",
		currencyCode: "USD",
		population: "65859",
		capital: "Majuro",
		continentName: "Oceania",
	},
	{
		countryCode: "MK",
		countryName: "Macedonia",
		currencyCode: "MKD",
		population: "2062294",
		capital: "Skopje",
		continentName: "Europe",
	},
	{
		countryCode: "ML",
		countryName: "Mali",
		currencyCode: "XOF",
		population: "13796354",
		capital: "Bamako",
		continentName: "Africa",
	},
	{
		countryCode: "MM",
		countryName: "Myanmar [Burma]",
		currencyCode: "MMK",
		population: "53414374",
		capital: "Naypyitaw",
		continentName: "Asia",
	},
	{
		countryCode: "MN",
		countryName: "Mongolia",
		currencyCode: "MNT",
		population: "3086918",
		capital: "Ulan Bator",
		continentName: "Asia",
	},
	{
		countryCode: "MO",
		countryName: "Macao",
		currencyCode: "MOP",
		population: "449198",
		capital: "Macao",
		continentName: "Asia",
	},
	{
		countryCode: "MP",
		countryName: "Northern Mariana Islands",
		currencyCode: "USD",
		population: "53883",
		capital: "Saipan",
		continentName: "Oceania",
	},
	{
		countryCode: "MQ",
		countryName: "Martinique",
		currencyCode: "EUR",
		population: "432900",
		capital: "Fort-de-France",
		continentName: "North America",
	},
	{
		countryCode: "MR",
		countryName: "Mauritania",
		currencyCode: "MRO",
		population: "3205060",
		capital: "Nouakchott",
		continentName: "Africa",
	},
	{
		countryCode: "MS",
		countryName: "Montserrat",
		currencyCode: "XCD",
		population: "9341",
		capital: "Plymouth",
		continentName: "North America",
	},
	{
		countryCode: "MT",
		countryName: "Malta",
		currencyCode: "EUR",
		population: "403000",
		capital: "Valletta",
		continentName: "Europe",
	},
	{
		countryCode: "MU",
		countryName: "Mauritius",
		currencyCode: "MUR",
		population: "1294104",
		capital: "Port Louis",
		continentName: "Africa",
	},
	{
		countryCode: "MV",
		countryName: "Maldives",
		currencyCode: "MVR",
		population: "395650",
		capital: "Malé",
		continentName: "Asia",
	},
	{
		countryCode: "MW",
		countryName: "Malawi",
		currencyCode: "MWK",
		population: "15447500",
		capital: "Lilongwe",
		continentName: "Africa",
	},
	{
		countryCode: "MX",
		countryName: "Mexico",
		currencyCode: "MXN",
		population: "112468855",
		capital: "Mexico City",
		continentName: "North America",
	},
	{
		countryCode: "MY",
		countryName: "Malaysia",
		currencyCode: "MYR",
		population: "28274729",
		capital: "Kuala Lumpur",
		continentName: "Asia",
	},
	{
		countryCode: "MZ",
		countryName: "Mozambique",
		currencyCode: "MZN",
		population: "22061451",
		capital: "Maputo",
		continentName: "Africa",
	},
	{
		countryCode: "NA",
		countryName: "Namibia",
		currencyCode: "NAD",
		population: "2128471",
		capital: "Windhoek",
		continentName: "Africa",
	},
	{
		countryCode: "NC",
		countryName: "New Caledonia",
		currencyCode: "XPF",
		population: "216494",
		capital: "Noumea",
		continentName: "Oceania",
	},
	{
		countryCode: "NE",
		countryName: "Niger",
		currencyCode: "XOF",
		population: "15878271",
		capital: "Niamey",
		continentName: "Africa",
	},
	{
		countryCode: "NF",
		countryName: "Norfolk Island",
		currencyCode: "AUD",
		population: "1828",
		capital: "Kingston",
		continentName: "Oceania",
	},
	{
		countryCode: "NG",
		countryName: "Nigeria",
		currencyCode: "NGN",
		population: "154000000",
		capital: "Abuja",
		continentName: "Africa",
	},
	{
		countryCode: "NI",
		countryName: "Nicaragua",
		currencyCode: "NIO",
		population: "5995928",
		capital: "Managua",
		continentName: "North America",
	},
	{
		countryCode: "NL",
		countryName: "Netherlands",
		currencyCode: "EUR",
		population: "16645000",
		capital: "Amsterdam",
		continentName: "Europe",
	},
	{
		countryCode: "NO",
		countryName: "Norway",
		currencyCode: "NOK",
		population: "5009150",
		capital: "Oslo",
		continentName: "Europe",
	},
	{
		countryCode: "NP",
		countryName: "Nepal",
		currencyCode: "NPR",
		population: "28951852",
		capital: "Kathmandu",
		continentName: "Asia",
	},
	{
		countryCode: "NR",
		countryName: "Nauru",
		currencyCode: "AUD",
		population: "10065",
		capital: "Yaren",
		continentName: "Oceania",
	},
	{
		countryCode: "NU",
		countryName: "Niue",
		currencyCode: "NZD",
		population: "2166",
		capital: "Alofi",
		continentName: "Oceania",
	},
	{
		countryCode: "NZ",
		countryName: "New Zealand",
		currencyCode: "NZD",
		population: "4252277",
		capital: "Wellington",
		continentName: "Oceania",
	},
	{
		countryCode: "OM",
		countryName: "Oman",
		currencyCode: "OMR",
		population: "2967717",
		capital: "Muscat",
		continentName: "Asia",
	},
	{
		countryCode: "PA",
		countryName: "Panama",
		currencyCode: "PAB",
		population: "3410676",
		capital: "Panama City",
		continentName: "North America",
	},
	{
		countryCode: "PE",
		countryName: "Peru",
		currencyCode: "PEN",
		population: "29907003",
		capital: "Lima",
		continentName: "South America",
	},
	{
		countryCode: "PF",
		countryName: "French Polynesia",
		currencyCode: "XPF",
		population: "270485",
		capital: "Papeete",
		continentName: "Oceania",
	},
	{
		countryCode: "PG",
		countryName: "Papua New Guinea",
		currencyCode: "PGK",
		population: "6064515",
		capital: "Port Moresby",
		continentName: "Oceania",
	},
	{
		countryCode: "PH",
		countryName: "Philippines",
		currencyCode: "PHP",
		population: "99900177",
		capital: "Manila",
		continentName: "Asia",
	},
	{
		countryCode: "PK",
		countryName: "Pakistan",
		currencyCode: "PKR",
		population: "184404791",
		capital: "Islamabad",
		continentName: "Asia",
	},
	{
		countryCode: "PL",
		countryName: "Poland",
		currencyCode: "PLN",
		population: "38500000",
		capital: "Warsaw",
		continentName: "Europe",
	},
	{
		countryCode: "PM",
		countryName: "Saint Pierre and Miquelon",
		currencyCode: "EUR",
		population: "7012",
		capital: "Saint-Pierre",
		continentName: "North America",
	},
	{
		countryCode: "PN",
		countryName: "Pitcairn Islands",
		currencyCode: "NZD",
		population: "46",
		capital: "Adamstown",
		continentName: "Oceania",
	},
	{
		countryCode: "PR",
		countryName: "Puerto Rico",
		currencyCode: "USD",
		population: "3916632",
		capital: "San Juan",
		continentName: "North America",
	},
	{
		countryCode: "PS",
		countryName: "Palestine",
		currencyCode: "ILS",
		population: "3800000",
		capital: "",
		continentName: "Asia",
	},
	{
		countryCode: "PT",
		countryName: "Portugal",
		currencyCode: "EUR",
		population: "10676000",
		capital: "Lisbon",
		continentName: "Europe",
	},
	{
		countryCode: "PW",
		countryName: "Palau",
		currencyCode: "USD",
		population: "19907",
		capital: "Melekeok",
		continentName: "Oceania",
	},
	{
		countryCode: "PY",
		countryName: "Paraguay",
		currencyCode: "PYG",
		population: "6375830",
		capital: "Asunción",
		continentName: "South America",
	},
	{
		countryCode: "QA",
		countryName: "Qatar",
		currencyCode: "QAR",
		population: "840926",
		capital: "Doha",
		continentName: "Asia",
	},
	{
		countryCode: "RE",
		countryName: "Réunion",
		currencyCode: "EUR",
		population: "776948",
		capital: "Saint-Denis",
		continentName: "Africa",
	},
	{
		countryCode: "RO",
		countryName: "Romania",
		currencyCode: "RON",
		population: "21959278",
		capital: "Bucharest",
		continentName: "Europe",
	},
	{
		countryCode: "RS",
		countryName: "Serbia",
		currencyCode: "RSD",
		population: "7344847",
		capital: "Belgrade",
		continentName: "Europe",
	},
	{
		countryCode: "RU",
		countryName: "Russia",
		currencyCode: "RUB",
		population: "140702000",
		capital: "Moscow",
		continentName: "Europe",
	},
	{
		countryCode: "RW",
		countryName: "Rwanda",
		currencyCode: "RWF",
		population: "11055976",
		capital: "Kigali",
		continentName: "Africa",
	},
	{
		countryCode: "SA",
		countryName: "Saudi Arabia",
		currencyCode: "SAR",
		population: "25731776",
		capital: "Riyadh",
		continentName: "Asia",
	},
	{
		countryCode: "SB",
		countryName: "Solomon Islands",
		currencyCode: "SBD",
		population: "559198",
		capital: "Honiara",
		continentName: "Oceania",
	},
	{
		countryCode: "SC",
		countryName: "Seychelles",
		currencyCode: "SCR",
		population: "88340",
		capital: "Victoria",
		continentName: "Africa",
	},
	{
		countryCode: "SD",
		countryName: "Sudan",
		currencyCode: "SDG",
		population: "35000000",
		capital: "Khartoum",
		continentName: "Africa",
	},
	{
		countryCode: "SE",
		countryName: "Sweden",
		currencyCode: "SEK",
		population: "9828655",
		capital: "Stockholm",
		continentName: "Europe",
	},
	{
		countryCode: "SG",
		countryName: "Singapore",
		currencyCode: "SGD",
		population: "4701069",
		capital: "Singapore",
		continentName: "Asia",
	},
	{
		countryCode: "SH",
		countryName: "Saint Helena",
		currencyCode: "SHP",
		population: "7460",
		capital: "Jamestown",
		continentName: "Africa",
	},
	{
		countryCode: "SI",
		countryName: "Slovenia",
		currencyCode: "EUR",
		population: "2007000",
		capital: "Ljubljana",
		continentName: "Europe",
	},
	{
		countryCode: "SJ",
		countryName: "Svalbard and Jan Mayen",
		currencyCode: "NOK",
		population: "2550",
		capital: "Longyearbyen",
		continentName: "Europe",
	},
	{
		countryCode: "SK",
		countryName: "Slovakia",
		currencyCode: "EUR",
		population: "5455000",
		capital: "Bratislava",
		continentName: "Europe",
	},
	{
		countryCode: "SL",
		countryName: "Sierra Leone",
		currencyCode: "SLL",
		population: "5245695",
		capital: "Freetown",
		continentName: "Africa",
	},
	{
		countryCode: "SM",
		countryName: "San Marino",
		currencyCode: "EUR",
		population: "31477",
		capital: "San Marino",
		continentName: "Europe",
	},
	{
		countryCode: "SN",
		countryName: "Senegal",
		currencyCode: "XOF",
		population: "12323252",
		capital: "Dakar",
		continentName: "Africa",
	},
	{
		countryCode: "SO",
		countryName: "Somalia",
		currencyCode: "SOS",
		population: "10112453",
		capital: "Mogadishu",
		continentName: "Africa",
	},
	{
		countryCode: "SR",
		countryName: "Suriname",
		currencyCode: "SRD",
		population: "492829",
		capital: "Paramaribo",
		continentName: "South America",
	},
	{
		countryCode: "SS",
		countryName: "South Sudan",
		currencyCode: "SSP",
		population: "8260490",
		capital: "Juba",
		continentName: "Africa",
	},
	{
		countryCode: "ST",
		countryName: "São Tomé and Príncipe",
		currencyCode: "STD",
		population: "175808",
		capital: "São Tomé",
		continentName: "Africa",
	},
	{
		countryCode: "SV",
		countryName: "El Salvador",
		currencyCode: "USD",
		population: "6052064",
		capital: "San Salvador",
		continentName: "North America",
	},
	{
		countryCode: "SX",
		countryName: "Sint Maarten",
		currencyCode: "ANG",
		population: "37429",
		capital: "Philipsburg",
		continentName: "North America",
	},
	{
		countryCode: "SY",
		countryName: "Syria",
		currencyCode: "SYP",
		population: "22198110",
		capital: "Damascus",
		continentName: "Asia",
	},
	{
		countryCode: "SZ",
		countryName: "Swaziland",
		currencyCode: "SZL",
		population: "1354051",
		capital: "Mbabane",
		continentName: "Africa",
	},
	{
		countryCode: "TC",
		countryName: "Turks and Caicos Islands",
		currencyCode: "USD",
		population: "20556",
		capital: "Cockburn Town",
		continentName: "North America",
	},
	{
		countryCode: "TD",
		countryName: "Chad",
		currencyCode: "XAF",
		population: "10543464",
		capital: "N'Djamena",
		continentName: "Africa",
	},
	{
		countryCode: "TF",
		countryName: "French Southern Territories",
		currencyCode: "EUR",
		population: "140",
		capital: "Port-aux-Français",
		continentName: "Antarctica",
	},
	{
		countryCode: "TG",
		countryName: "Togo",
		currencyCode: "XOF",
		population: "6587239",
		capital: "Lomé",
		continentName: "Africa",
	},
	{
		countryCode: "TH",
		countryName: "Thailand",
		currencyCode: "THB",
		population: "67089500",
		capital: "Bangkok",
		continentName: "Asia",
	},
	{
		countryCode: "TJ",
		countryName: "Tajikistan",
		currencyCode: "TJS",
		population: "7487489",
		capital: "Dushanbe",
		continentName: "Asia",
	},
	{
		countryCode: "TK",
		countryName: "Tokelau",
		currencyCode: "NZD",
		population: "1466",
		capital: "",
		continentName: "Oceania",
	},
	{
		countryCode: "TL",
		countryName: "East Timor",
		currencyCode: "USD",
		population: "1154625",
		capital: "Dili",
		continentName: "Oceania",
	},
	{
		countryCode: "TM",
		countryName: "Turkmenistan",
		currencyCode: "TMT",
		population: "4940916",
		capital: "Ashgabat",
		continentName: "Asia",
	},
	{
		countryCode: "TN",
		countryName: "Tunisia",
		currencyCode: "TND",
		population: "10589025",
		capital: "Tunis",
		continentName: "Africa",
	},
	{
		countryCode: "TO",
		countryName: "Tonga",
		currencyCode: "TOP",
		population: "122580",
		capital: "Nuku'alofa",
		continentName: "Oceania",
	},
	{
		countryCode: "TR",
		countryName: "Turkey",
		currencyCode: "TRY",
		population: "77804122",
		capital: "Ankara",
		continentName: "Asia",
	},
	{
		countryCode: "TT",
		countryName: "Trinidad and Tobago",
		currencyCode: "TTD",
		population: "1228691",
		capital: "Port of Spain",
		continentName: "North America",
	},
	{
		countryCode: "TV",
		countryName: "Tuvalu",
		currencyCode: "AUD",
		population: "10472",
		capital: "Funafuti",
		continentName: "Oceania",
	},
	{
		countryCode: "TW",
		countryName: "Taiwan",
		currencyCode: "TWD",
		population: "22894384",
		capital: "Taipei",
		continentName: "Asia",
	},
	{
		countryCode: "TZ",
		countryName: "Tanzania",
		currencyCode: "TZS",
		population: "41892895",
		capital: "Dodoma",
		continentName: "Africa",
	},
	{
		countryCode: "UA",
		countryName: "Ukraine",
		currencyCode: "UAH",
		population: "45415596",
		capital: "Kiev",
		continentName: "Europe",
	},
	{
		countryCode: "UG",
		countryName: "Uganda",
		currencyCode: "UGX",
		population: "33398682",
		capital: "Kampala",
		continentName: "Africa",
	},
	{
		countryCode: "UM",
		countryName: "U.S. Minor Outlying Islands",
		currencyCode: "USD",
		population: "0",
		capital: "",
		continentName: "Oceania",
	},
	{
		countryCode: "US",
		countryName: "United States",
		currencyCode: "USD",
		population: "310232863",
		capital: "Washington",
		continentName: "North America",
	},
	{
		countryCode: "UY",
		countryName: "Uruguay",
		currencyCode: "UYU",
		population: "3477000",
		capital: "Montevideo",
		continentName: "South America",
	},
	{
		countryCode: "UZ",
		countryName: "Uzbekistan",
		currencyCode: "UZS",
		population: "27865738",
		capital: "Tashkent",
		continentName: "Asia",
	},
	{
		countryCode: "VA",
		countryName: "Vatican City",
		currencyCode: "EUR",
		population: "921",
		capital: "Vatican City",
		continentName: "Europe",
	},
	{
		countryCode: "VC",
		countryName: "Saint Vincent and the Grenadines",
		currencyCode: "XCD",
		population: "104217",
		capital: "Kingstown",
		continentName: "North America",
	},
	{
		countryCode: "VE",
		countryName: "Venezuela",
		currencyCode: "VEF",
		population: "27223228",
		capital: "Caracas",
		continentName: "South America",
	},
	{
		countryCode: "VG",
		countryName: "British Virgin Islands",
		currencyCode: "USD",
		population: "21730",
		capital: "Road Town",
		continentName: "North America",
	},
	{
		countryCode: "VI",
		countryName: "U.S. Virgin Islands",
		currencyCode: "USD",
		population: "108708",
		capital: "Charlotte Amalie",
		continentName: "North America",
	},
	{
		countryCode: "VN",
		countryName: "Vietnam",
		currencyCode: "VND",
		population: "89571130",
		capital: "Hanoi",
		continentName: "Asia",
	},
	{
		countryCode: "VU",
		countryName: "Vanuatu",
		currencyCode: "VUV",
		population: "221552",
		capital: "Port Vila",
		continentName: "Oceania",
	},
	{
		countryCode: "WF",
		countryName: "Wallis and Futuna",
		currencyCode: "XPF",
		population: "16025",
		capital: "Mata-Utu",
		continentName: "Oceania",
	},
	{
		countryCode: "WS",
		countryName: "Samoa",
		currencyCode: "WST",
		population: "192001",
		capital: "Apia",
		continentName: "Oceania",
	},
	{
		countryCode: "XK",
		countryName: "Kosovo",
		currencyCode: "EUR",
		population: "1800000",
		capital: "Pristina",
		continentName: "Europe",
	},
	{
		countryCode: "YE",
		countryName: "Yemen",
		currencyCode: "YER",
		population: "23495361",
		capital: "Sanaa",
		continentName: "Asia",
	},
	{
		countryCode: "YT",
		countryName: "Mayotte",
		currencyCode: "EUR",
		population: "159042",
		capital: "Mamoudzou",
		continentName: "Africa",
	},
	{
		countryCode: "ZA",
		countryName: "South Africa",
		currencyCode: "ZAR",
		population: "49000000",
		capital: "Pretoria",
		continentName: "Africa",
	},
	{
		countryCode: "ZM",
		countryName: "Zambia",
		currencyCode: "ZMW",
		population: "13460305",
		capital: "Lusaka",
		continentName: "Africa",
	},
	{
		countryCode: "ZW",
		countryName: "Zimbabwe",
		currencyCode: "ZWL",
		population: "13061000",
		capital: "Harare",
		continentName: "Africa",
	},
];
